(function () {
  "use strict";

  angular.module("EntrakV5").constant("LANG", {
    en: {
      header: {
        title: "Landlord portal",
        tenant: "Tenants",
        building: "Buildings",
        profile: "Profiles",
        distributor: "Distributors",
        slGateway: "SL gateways",
        gateway: "Gateways",
        device: "Devices",
        timetable: "Schedules",
        city: "Cities",
        meter: "Meters",
        ranking: "Ranking",
        occupiedArea: "Occupied area",
        hub: "Hubs",
        en: "English",
        zh: "繁體中文",
        cn: "简体中文",
        enShort: "Eng",
        zhShort: "繁",
        cnShort: "简",
        logout: "Sign out",
      },
      nodeType: {
        company: "Company",
        // site: "Site",
        // building: "Building",
        floor: "Zone",
        // unit: "Unit",
        // zone: "Zone",
        room: "Room",
        workstation: "Workstation",
      },
      applicationType: {
        light: "Light",
        aircon: "Aircon",
        gateway: "Gateway",
        button: "Button",
        lightSensor: "Light sensor",
        iaqSensor: "IAQ sensor",
        motionSensor: "Occupancy sensor",
        fan: "Fan",
        switch: "Wall switch",
        door: "Door",
        unknown: "Unknown",
        energyMeter: "Energy meter",
        thermometer: "Thermometer",
      },
      scheduleCtrl: {
        autoDesc: "Devices will turn ON automatically",
        manualDesc: "Manual turn ON for the session",
        isAltSat: "Alternative Saturday",
        isLongWeek: "This week is a long week",
        autoLegend: "Auto ON",
        manualLegend: "Manual ON",
        addDay: "Add a day",
      },
      uploadCtrl: {
        upload: "Upload",
        invalidFormat: "Please select JPG/PNG file.",
        max10Mb: "Maximum file size is 10MB",
      },
      label: {
        del: "Del",
        num: "Num",
        noData: "No data",
        today: "Today",
        yesterday: "Yesterday",
        search: "Search",
        activated: "Activated",
        sent: "Sent!",
        DASHBOARD_ADMIN: "Dashboard admin",
        TENANT_USER: "Tenant user",
        ZONE_ADMIN: "Zone admin",
        TENANT_ADMIN: "Tenant admin",
        LANDLORD_USER: "Landlord user",
        LANDLORD_ADMIN: "Landlord admin",
        SUPERUSER: "Super user",
        UNKNOWN: "Unknown",
        noTenantAccess: "No access",
        needTenantAccess: "Need access",
        date: "Date",
        delta: "Delta ${num}",
        default: "Default",
        scanDesc:
          "Scanning in progress, please refresh the page after 2 minutes to see the result.",
        createVirtualHubDesc:
          "Creating virtual hub, please refresh the page after a minute to see the new hub.",
        degree: "°C",
        ppm: "ppm",
        ppb: "ppb",
        ugm3: "μg/m³",
        m2: "m²",
        lx: "lx",
        kwh: "kWh",
        on: "ON",
        off: "OFF",
        backupGateway: "The gateway data backup was created successfully.",
        restoreGateway:
          "The gateway data was restored from backup successfully.",
        high: "High",
        medium: "Medium",
        low: "Low",
        auto: "Auto",
        schName: "Schedule name",
        startAction: "Start action",
        endAction: "End action",
        lastTrigger: "Last trigger",
        normal: "Normal",
        cooling: "Cooling",
        warming: "Warming",
        day1: "1d",
        day3: "3d",
        day7: "1w",
        ems: "EMS",
        tep: "TEP",
        all: "All",
        enable: "Enable",
        enabled: "Enabled",
        disabled: "Disabled",
        enabledDisabled: "Enabled and disabled",
        noteVirtualHubGeneration:
          "This function requires the Tenant Code to generate the devices' serial, please make sure the value is set.",
      },
      button: {
        enforce: "Enforce",
        delete: "Delete",
        cancel: "Cancel",
        invite: "Invite",
        promote: "Promote",
        create: "Create",
        edit: "Edit",
        editHierarchy: "Assign data point",
        finish: "Finish",
        deleteTenant: "Delete tenant",
        editTenant: "Edit tenant",
        editFloor: "Edit zone",
        cutFloor: "Create tenant unit",
        confirm: "Confirm",
        ok: "Ok",
        save: "Save",
        saveChange: "Save changes",
        back: "Back",
        add: "Add",
        uploadFloorPlan: "Upload floor plan",
        signIn: "Sign in",
        next: "Next",
        reset: "Reset",
        goSignIn: "Go back to sign in",
        done: "Done",
        createNew: "Create new",
        addAccount: "Add account",
        reinvite: "Reinvite",
        deleteAccount: "Delete this account",
        tenantMoveOut: "Move out",
        manageLogin: "Manage login",
        scanGateway: "Scan gateway",
        scanDevice: "Scan device",
        regDevice: "Refresh status",
        regAllDevices: "Refresh all status",
        createVirtualHub: "Create virtual hub",
        createSLGateway: "Create SL gateway",
        backup: "Backup",
        restore: "Restore",
        toggleOverview: "Toggle overview",
        refreshSelectedGw: "Refresh selected gateways",
        refresh: "Refresh",
        normal: "Normal",
        createTenant: "Add tenant", //create tenant only/or zone together
        assignTenantZone: "Add zone to tenant", //existing tenant to new/existing zone
        uploadLogo: "Upload Logo",
        dlCsv: "Download CSV",
        export: "Export",
      },
      error: {
        domainNotFound: "The inputted domain is not valid.",
        duplicatedDomain: "The inputted domain already exist.",
        generalCreateTenantAndFloor: "Unable to create tenant and zone.",
        generalCreateTenant: "Unable to create tenant",
        generalUpdateTenant: "Unable to update tenant.",
        generalCreateFloor: "Unable to create zone.",
        generalUpdateFloor: "Unable to update zone.",
        generalUpdateHub: "Unable to update hub.",
        generalUpdateGateway: "Unable to update gateway.",
        generalUpdateDevice: "Unable to update device.",
        generalUnpairDevice: "Unable to unpair device.",
        generalCreateAccount: "Unable to create account.",
        generalUpdateAccount: "Unable to update account.",
        generalCreateLandlord: "Unable to create landlord.",
        generalUpdateLandlord: "Unable to update landlord.",
        generalCreateCity: "Unable to create city.",
        generalUpdateCity: "Unable to update city.",
        generalUpdate: "Unable to process.",
        generalCreateUpdateBuilding: "Unable to create/update building.",
        invalidEmail: "Invalid email.",
        duplicatedEmail: "This email is already in use.",
        generalGetDataFail: "Unable to get data, please retry later.",
        generalDeleteFail: "unable to delete item",
        generalSaveFail: "unable to save item",
        generalInviteFail: "Unable to send invitation.",
        generalControlFail: "Unable to control device",
        generalScanFail: "Unable to scan",
        generalRestoreFail: "Unable to restore",
        generalBackupFail: "Unable to backup",
        invalidTimezone: "Invalid timezone",
        tenantNotFound:
          "Unable to redirect, this zone is not associated with any tenant.",
        invalidFormula: "Invalid expression, please check again.",
      },
      profile: {
        access: "Access",
        addAccount: "Add account",
        email: "Email",
        role: "Roles",
        tenantRole: "Tenant admin",
        tenant: "Tenant",
        status: "Status",
        floor: "Zone",
        tenantUser: "Tenant users",
        landlordUser: "Landlord users",
        allUser: "All users",
        popup: {
          deleteAccount: "Delete account",
          deleteAccountDesc: "Are you sure you want to delete account",
          deleteFloor: "Remove zone",
          addLandlordUser: "Add landlord user",
          editLandlordUser: "Edit landlord user",
          tenantRole: "Tenant portal access",
          isTenantAdmin: "He/She is also a tenant admin",
          invite: "Invite",
          promote: "Promote",
          inviteAccountDesc:
            "Send an invitation to create an account. The new account will be able to access this portal.",
          promoteAccountDesc:
            "Promote a person from the tenant to access this portal.",
          inviteAdminDesc:
            "Send and invitation to the new tenant admin to create an account.",
          promoteAdminDesc:
            "Promote a person from the company to be a new tenant admin.",
          username: "Username",
          password: "Password",
          genPwd: "Generate password",
        },
      },
      distributor: {
        summary: "Summary",
        tenant: "Tenant",
        distributor: "Distributor",
        building: "Building",
        floor: "Zone",
        name: "Name",
        status: "Status",
        role: "Roles",
        distributorAccount: "Distributor accounts",
        distributorTab: "Distributors",
        tenantTab: "Tenants",
        deviceTab: "Devices",
        addDistributor: "Add distributor",
        addDistributorAccount: "Add distributor account",
        serial: "Serial ID",
        hubId: "Hub ID",
        deviceId: "Device ID",
        type: "Type",
        source: "Source",
        version: "Version",
        remark: "Remark",
        bacnet: "BACnet BMS",
        popup: {
          invite: "Invite",
          promote: "Promote",
          deleteDistributor: "Delete distributor",
          deleteDistributorAccount: "Delete distributor account",
          distributorName: "Distributor name",
          deleteDistributorDesc: "Are you sure you want to delete distributor",
          buildingName: "Building name",
          email: "Email",
          domain: "Domain",
          deleteDistributorAccountDesc:
            "Are you sure you want to delete distributor account",
          tenantName: "Tenant name",
          deleteDevice: "Delete Device",
          deleteDeviceDesc: "Are you sure you want to delete device",
        },
      },
      hub: {
        id: "ID",
        serial: "Serial ID",
        serialId: "Serial (ID)",
        remark: "Remark",
        version: "Version",
        cargoList: "Cargo list",
        building: "Buildings",
        updateHub: "Update hub version",
        updateHubModule: "Update hub module",
        restartHub: "Restart hub",
        restartHubModule: "Restart hub module",
        popup: {
          currentVersion: "Current version",
          updateTo: "Update to",
          editHub: "Edit hub",
          deleteHub: "Delete hub",
          deleteHubDesc: "Are you sure you want to delete hub",
          module: "Module",
        },
      },
      meter: {
        nodeReferenceId: "Node reference ID",
        accessType: "Access type",
        editHierarchyNode: "Edit hierarchy node",
        tariff: "Tariff",
        pendingRecap: "Recalculating: ",
        recalcEms: "Re-calc EMS",
        recalcEmsData: "Re-calculate EMS data",
        sd: "Standard deviation",
        mean: "Mean",
        statistic: "Statistic",
        dataPointId: "Data point ID",
        meterId: "Meter ID (Serial)",
        meterSerial: "Meter serial",
        dataPointStatus: "Data point status",
        dataPointName: "Data point name",
        dataPointScaler: "Scaler",
        meterStatus: "Meter status",
        meterType: "Meter type",
        failCount: "Fail count",
        reportLastSent: "Report last sent",
        lastOnline: "Last online",
        hasKva: "Has kVA",
        dataflowMode: "Dataflow mode",
        dataType: "Data type",
        xmlUrl: "XML Url",
        rawData: "Raw data(kWh): ${count}",
        rawDataWater: "Raw data(L): ${count}",
        description: "Description",
        createMeter: "Create meter",
        createNode: "Create node",
        createVirtualDataPoint: "Create virtual data point",
        updateVirtualDataPoint: "Update virtual data point",
        expression: "Expression",
        expression: "Expression",
        expressionDesc:
          "You can double click the data points on the left to add them into the expression.",
        updateMeter: "Edit meter",
        createDataPoint: "Create data point",
        createAllDataPoint: "Create all data points",
        updateDataPoint: "Update data point",
        tenant: "Tenant",
        serial: "Serial",
        objectId: "Object ID",
        objectType: "Object Type",
        slaveId: "Slave ID",
        registerId: "Register ID",
        registerType: "Register Type",
        deleteMeter: "Delete meter",
        deleteMeterDesc: "Are you sure you want to delete meter",
        deleteDataPoint: "Delete data point",
        deleteDataPointDesc: "Are you sure you want to delete data point",
        deleteVirtualDataPoint: "Delete virtual data point",
        deleteVirtualDataPointDesc:
          "Are you sure you want to delete this virtual data point? It will also be removed from the hierarchy.",
        deletePOV: "Delete POV",
        deletePOVDesc:
          "Are you sure you want to delete this POV from the hierarchy?",
        deleteSite: "Delete site",
        deleteSiteDesc:
          "Are you sure you want to delete this site from the hierarchy?",
        bmsType: "BMS type",
        dataTransmissionUnit: "Data transmission unit",
        hubId: "Hub ID",
        meter: "Meter",
        virtualDataPoint: "Virtual data point",
        hierarchy: "Hierarchy",
        recapData: "Recap data",
        recapHour: "Recap this hour",
        recapDataAll: "Recap all data",
        startTime: "Start time",
        endTime: "End time",
        target: "Target",
        dataTopic: "Data topic",
        heartbeatTopic: "Heartbeat topic",
        payloadParser: "Payload parser",
        bacnet: "BACnet",
        modbus: "Modbus",
        refId: "Reference ID",
        path: "Path",
        unbindDataPoint: "Unbind data point",
        unbindDataPointDesc:
          "Are you sure you want to unbind this data point from POV",
        refreshChart: "Refresh chart",
        createChild: "Create child POV",
        childName: "Child name",
        nodeName: "Node name",
        address: "Address",
        city: "City",
        parentName: "Parent name",
        parentType: "Parent type",
        povUnit: "Pov unit",
        rename: "Rename",
        nodeName: "Node name",
        nodeType: "Node type",
        renameNode: "Rename node",
        energyMeter: "Energy Meter",
        waterMeter: "Water Meter",
        isEnabled: "Enabled",
        enable: "Enable",
        disable: "Disable",
        remark: "Remark",
        noData: "No Data",
        energy: "Energy",
        water: "Water",
        meterInfo: "Meter Info",
        meterDatapointInfo: "Meter Datapoint info",
        meterDatapointChart: "Meter Datapoint Chart",
        seeLess: "See Less",
        seeMore: "See More",
        expressionDps: "Datapoint used in expression",
        virtual: "Virtual",
        upload: "Upload data",
        uploadDesc: "Are you sure you want to upload data to this meter",
        recalcSummary: "Recalculate summary",
        emsMeterId: "EMS Meter ID",
        emsSysId: "EMS System ID",
        hierarchyId: "Hierarchy ID",
        lastHour: "Last hour ",
        yesterday: "Yesterday ",
        name: "Name",
        totalConsumption: "Expression result",
        saved: "Saved!",
        createTemperatureDataPoint: "Create temperature data point",
        deviceSerial: "Device serial",
        devices: "Devices",
        calcChild: "Calculate child POVs",
        selectCheckDateTime: "Select specific time to show additional results.",
        extraDate: "Selected hour",
      },
      slGateway: {
        host: "Host",
        popup: {
          deleteSLGateway: "Delete SL gateway",
          deleteSLGatewayDesc: "Are you sure you want to delete SL gateway",
        },
      },
      building: {
        latitude: "Latitude",
        longitude: "Longitude",
        country: "Country/City",
        buildingName: "Building name",
        tenant: "Tenant",
        hub: "Hubs",
        addBuilding: "Add building",
        popup: {
          editBuilding: "Edit building",
          hub: "Hubs",
          deleteBuilding: "Delete building",
          deleteBuildingDesc: "Are you sure you want to delete building",
        },
      },
      tenant: {
        tenant: "Tenant",
        tenantTab: "Tenants",
        editTenant: "Edit tenant",
        viewTenantAdmin: "View admin",
        noTenant: "Zones without tenant",
        addFloor: "Add zone",
        editFloor: "Edit zone",
        building: "Building",
        floor: "Zone",
        tenantSearch: "Search tenant",
        hideTemperature: "Hide temperature",
        assignTenant: "Add zone to tenant",
        tenantAdminTab: "Tenant admins",
        addTenantAdmin: "Add tenant admin",
        gatewayTab: "Gateways",
        editGateways: "Edit gateways",
        lightTab: "Lights",
        airconTab: "Aircons",
        buttonTab: "Buttons",
        sensorTab: "Sensors",
        fanTab: "Fans",
        switchTab: "Wall switches",
        unknownTab: "Unknowns",
        name: "Name",
        email: "Email",
        version: "Version",
        status: "Status",
        time: "Time",
        temperature: "Temperature",
        dimLv: "Brightness",
        pm25: "PM2.5",
        tvoc: "TVOC",
        co2: "CO₂",
        humidity: "Humidity",
        setPoint: "Set point",
        hubSerial: "Hub serial",
        showGatewayDevice: "Showing devices for gateway ${id}",
        locations: "Locations",
        ctrlLogic: "Control logic",
        inSchHI: "In schedule heat index",
        outSchHI: "Out schedule heat index",
        co2Concentration: "CO₂ concentration",
        exportDeviceActivity: "Export device activites",
        startDate: "Start date",
        endDate: "End date",
        timezone: "Time zone",
        popup: {
          userpool: "Userpool",
          createDummy: "Create dummy hub and gateway",
          editDevice: "Edit device",
          floorName: "Zone name",
          floorPlan: "Please upload a floor plan",
          logo: "Company logo",
          distributor: "Distributor",
          tenantName: "Tenant name",
          tenantDomain: "Tenant domain",
          deviceType: "Device type",
          applicationType: "Application type",
          fanSpeedProfile: "Fan speed profile",
          fanSpeedLv: "Fan speed",
          apiKey: "API key",
          maxExtendHrs: "Max extension hour",
          defaultExtendMins: "Default extension minutes",
          newZone: "Create new zone",
          alsoCreateZone: "Also create a zone",
          gateway: "Gateways",
          floorGateway: "Zone gateways",
          deleteFloorDesc:
            "If you want to delete this zone, you need to remove all gateways from it.",
          tenantMoveOutDesc:
            "This action is non-reversible, please input the zone name to confirm.",
          deleteTenant: "Delete Tenant",
          deleteTenantDesc:
            "This action is non-reversible, please input the tenant name to confirm.",
          deleteTenantAdmin: "Delete tenant admin",
          deleteTenantAdminDesc: "Are you sure you want to delete tenant admin",
          invite: "Invite",
          promote: "Promote",
          inviteAccountDesc:
            "Send an invitation to create an account. The new account will be able to access this portal.",
          promoteAccountDesc:
            "Promote a person from the tenant to access this portal.",
          inviteAdminDesc:
            "Send and invitation to the new tenant admin to create an account.",
          promoteAdminDesc:
            "Promote a person from the company to be a new tenant admin.",
          username: "Username",
          password: "Password",
          restoreGateway: "Restore gateway",
          fromGateway: "From other gateway",
          backupGateway: "Backup gateway",
          backupGatewayDesc: "Are you sure you want to backup gateway",
          deleteGateway: "Delete gateway",
          deleteGatewayDesc: "Are you sure you want to delete gateway",
          editGateway: "Edit gateway",
          unpairDevice: "Unpair device",
          unpairDeviceDesc: "Are you sure you want to unpair device",
          cutFloorDesc:
            "To duplicate this zone, first select the area in the floor plan, then select the devices you want to include. The original zone won't be changed.",
          allowedDomain: "Allowed domain",
          oauthProvider: "OAuth provider",
          microsoft: "Microsoft",
          microsoftId: "Microsoft 365 tenant id",
          google: "Google",
          googleId: "Google client id",
          domain: "Domain",
          deleteDomainTitle: "Delete domain",
          deleteDomainDesc: "Are you sure you want to delete this domain?",
          deleteOAuthTitle: "Delete OAuth provider",
          deleteOAuthDesc:
            "Are you sure you want to delete this OAuth provider?",
          consentLink: "Consent link",
          alwaysOn: "Always on",
          pauseSchedule: "Pause schedule",
          nodeOnFloorplan: "Display node on floor plan",
          widget: "Show widget",
          aircon: "Aircon",
          lighting: "Lighting",
          iaq: "IAQ",
          rating: "GreenScore",
          energy: "Energy",
          ems: "EMS enabled",
          tep: "TEP enabled",
          hasSolar: "Has solar",
          hasIaq: "Has IAQ",
          tenantCode: "Tenant code",
        },
        backupDate: "Backup date",
        gatewayScan: "Scan devices",
        // editLocation: "Edit device location",
        online: "Online",
        offline: "Offline",
        serial: "Serial ID",
        device: "Device",
        // gatewayId: "Gateway ID",
        gatewaySerial: "Gateway serial",
        gatewaySerId: "Gateway Serial (ID)",
        // locationId: "Location ID",
        // locationDesc: "ID: ${id}, Floor: ${floorName}, (${x}, ${y})",
        // noLocationDesc: "No location assigned",
        remark: "Remark",
        defaultTemp: "Default temperature",
        temperatureDesc: "The value is within the range of [-3,3]",
        defaultFanSpeed: "Default fan speed",
        defaultDimLv: "Default brightness",
        deviceId: "Device ID",
        provider: "Provider",
        buildingFloor: "Building (zones)",
        // deviceIdAndSerial: "Device ID (Serial)",
        type: "Type",
        deviceTab: "Devices",
        unpair: "Unpair",
        // unpairDeviceDesc: "To activate the device again, please pair it with gateway or BMS.",
        editLocationTab: "Edit locations",
        editLocationDesc: "Drag the device bubble to the floor plan.",
        removeFromFloor: "Remove from zone",
        createDevice: "Create device",
        applicationType: "Application type",
        deviceType: "Device type",
        blink: "Blink",
        blinking: "Blinking...",
        on: "On",
        off: "Off",
        allBuilding: "All buildings",
        allCities: "All Cities",
        allStatus: "All status",
        gwOnlinePercent: "Gateway online %",
        gwOfflineByBuilding: "Gateway offline by Building",
        gwOfflineByFloor: "Gateway offline by zone",
        gwOfflineLog: "Gateway status log",
        deviceLog:
          "Device activities in last 7 days (only ${count} latest records)",
        deviceStatus: "Device status",
        hubOfflineLog: "Hub status log",
      },
      city: {
        cityId: "City ID",
        city: "City",
        country: "Country",
        timezone: "Time zone",
        holidayCode: "Holiday code",
        locationCode: "Location code",
        provider: "Provider",
        HK_OBSERVATORY: "Hong Kong Observatory",
        SG_OBSERVATORY: "Singapore Observatory",
        OPEN_WEATHER_MAP: "Open weather map",
        popup: {
          addCity: "Add city",
          editCity: "Edit city",
          deleteCity: "Delete city",
          deleteCityDesc: "Are you sure you want to delete city",
        },
      },
      ranking: {
        ranking: "Ranking",
        zoneName: "Zone Name",
        tenantName: "Tenant Name",
        inDoorTemperature: "In Door ",
        outDoorTemperature: "Out Door",
        country: "Country",
        timezone: "Time zone",
        holidayCode: "Holiday code",
        locationCode: "Location code",
        provider: "Provider",
        HK_OBSERVATORY: "Hong Kong Observatory",
        SG_OBSERVATORY: "Singapore Observatory",
        OPEN_WEATHER_MAP: "Open weather map",
        select: {
          city: "Select city",
          building: "Select building",
          tenant: "Select tenant",
        },
      },
      timetable: {
        triggered: "Triggered",
        triggerFail: "Fail to Trigger",
        notYetTrigger: "No need to trigger",
      },
      oa: {
        occupiedArea: "Occupied area",
        pov: "POV",
        area: "Area(m²)",
        name: "Name",
        remark: "Remark",
        tenant: "Tenant",
        effectiveDate: "Effective date",
        createOccupiedArea: "Create occupied area",
        areaRecord: "Area records",
        editOccupiedArea: "Edit occupied area",
        createOccupiedArea: "Create occupied area",
        uploadOccupiedArea: "Upload occupied areas excel",
        bindPOV: "Bind POV",
        setArea: "Set area",
        deleteOccupiedArea: "Delete occupied area",
        deleteOccupiedAreaDesc: "Are you sure you want to delete occupiad area",
        deleteOccupiedAreaEffectiveDate: "Delete area record",
        deleteOccupiedAreaEffectiveDateDesc:
          "Are you sure you want to delete area record",
        confirmAddArea: "Add area record",
        confirmAddOldAreaDesc:
          "You already have a newer area record, are you sure you want to add record for this date?",
      },
    },
    zh: {},
  });
})();
